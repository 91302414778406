import { Button } from '@/components/ui/button'
import { Head } from '@inertiajs/react'
import Logo from '@/components/logo'
import MiniLayout from '@/layouts/minimal-layout'

export default function ErrorPage({ status }: { status: string }) {
    const errorMessages = {
        503: {
            title: 'Layanan Tidak Tersedia',
            description: 'Maaf, kami sedang melakukan pemeliharaan pada website kami. Silakan periksa kembali nanti.',
        },
        500: { title: 'Terjadi Kesalahan Server', description: 'Whoops, ada yang salah pada server kami.' },
        404: { title: 'Halaman Tidak Ditemukan', description: 'Maaf, halaman yang Anda cari tidak dapat ditemukan.' },
        403: { title: 'Dilarang', description: 'Maaf, Anda dilarang mengakses halaman ini.' },
        401: { title: 'Tidak Diotorisasi', description: 'Maaf, Anda tidak diizinkan mengakses halaman ini.' },
        429: { title: 'Terlalu Banyak Permintaan', description: 'Silakan coba lagi nanti.' },
    }[status]
    return (
        <div className="bg-bg flex h-screen w-screen items-center">
            <Head title={errorMessages?.title} />
            <div className="z-40 container">
                <Logo className="mb-1 lg:mb-6" />
                <div className="max-w-md">
                    <div className="text-foreground text-5xl font-bold">{status}</div>
                    <p className="text-muted-foreground mt-2 text-2xl leading-normal font-light md:text-3xl">
                        {errorMessages?.title}
                    </p>
                    <p className="text-muted-foreground mt-2 mb-8">{errorMessages?.description}</p>

                    <Button appearance="solid">
                        <a href="/">Kembali ke halaman utaman</a>
                    </Button>
                </div>
            </div>
        </div>
    )
}

ErrorPage.layout = (page: React.ReactNode) => <MiniLayout children={page} />
